import * as React from "react"
import * as Loadable from "react-loadable"
import { useStaticQuery, graphql } from "gatsby"

export default (): JSX.Element => {
	type LoaderProps = Loadable.LoadingComponentProps

	const Loader: React.FC<LoaderProps> = (props: LoaderProps) => {
		return <div>Loading...</div>
	}

	const Content = Loadable({
		loader: () => import("../../components/content"),
		loading: Loader,
	})


	const [content, setContent] = React.useState(null)
	React.useEffect(() => {
		fetch(`${process.env.BASEPATH ?? ""}/api/content`)
			.then((nonJsonContent) => nonJsonContent.json())
			.then((data) => setContent(data))
	}, [])

	return <>{content && <Content isAdmin data={content.content} />}</>
}
